<template>
<div id="page-user-list">
  <div class="flex flex-wrap items-center">
    <!-- ITEMS PER PAGE -->
    <div class="flex-grow">
      <div>
        <vx-card title="Agent ตัวเกม">
          <vs-table :data="items" :noDataText="noDataText">
            <template slot="thead">
              <vs-th>code</vs-th>
              <vs-th>name</vs-th>
              <vs-th>agent url</vs-th>
              <vs-th></vs-th>
            </template>

            <template slot-scope="{ data }">
              <vs-tr :key="indextr" v-for="(tr, indextr) in data">
                <vs-td :data="tr.game_code">
                  {{ tr.game_code }}
                </vs-td>
                <vs-td :data="tr.game_name">
                  {{ tr.game_name }}
                </vs-td>
                <vs-td :data="tr.game_agent_url">
                  <a :href="tr.game_agent_url">{{ tr.game_agent_url }}</a>
                </vs-td>
              </vs-tr>
            </template>
          </vs-table>
        </vx-card>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import axios from '@/axios'

export default {
  components: {},
  data () {
    return {
      items: [],
      noDataText: 'โปรดรอสักครู่...'
    }
  },
  computed: {
    isSmallerScreen () {
      return this.$store.state.windowWidth < 768
    }
  },
  async created () {
    let error = null

    const request = await axios.get('games/list').catch((err) => {
      error = err
    })

    if (error) {
      this.$vs.notify({
        title: 'เกิดข้อผิดพลาด',
        text: 'ไม่สามารถดึงข้อมูลเกมได้ในขณะนี้',
        iconPack: 'feather',
        icon: 'icon-alert-circle',
        color: 'warning',
        position: 'top-right'
      })
      this.noDataText = 'ไม่พบรายชื่อเกมส์'
    }

    this.items = request.data.data
  }
}
</script>

<style lang="scss">
#profile-tabs {
  .vs-tabs--content {
    padding: 0;
  }
}
</style>
